export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34')
];

export const server_loads = [];

export const dictionary = {
		"/(site)": [10,[2],[3]],
		"/(site)/about-us": [11,[2],[3]],
		"/(site)/admin": [12,[2,4],[3]],
		"/app": [26,[7]],
		"/app/configure": [27,[7]],
		"/app/dashboard": [28,[7,8]],
		"/app/dashboard/my-designs": [29,[7,8,9]],
		"/app/dashboard/my-shop": [30,[7,8]],
		"/app/dashboard/news": [31,[7,8]],
		"/app/dashboard/orders": [32,[7,8]],
		"/app/dashboard/settings": [33,[7,8]],
		"/app/login": [34,[7]],
		"/(site)/contact-us": [13,[2],[3]],
		"/(site)/cookie-policy-uk": [14,[2],[3]],
		"/(site)/copyright-disclaimer": [15,[2],[3]],
		"/(site)/delivery-information": [16,[2],[3]],
		"/(site)/design-manager": [17,[2],[3]],
		"/(site)/ethical-concerns": [18,[2],[3]],
		"/(site)/my-account/(authed)/my-images": [19,[2,5,6],[3]],
		"/(site)/my-account/(authed)/orders": [20,[2,5,6],[3]],
		"/(site)/payment-success": [21,[2],[3]],
		"/(site)/privacy-policy": [22,[2],[3]],
		"/(site)/return-policy": [23,[2],[3]],
		"/(site)/sign-in": [24,[2],[3]],
		"/(site)/terms-and-conditions": [25,[2],[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';